import styled from '@emotion/styled'
import { AffiliationLogo } from 'app/components/AffiliationLogo'
import { Benefits } from 'app/components/Benefits'
import { HeroAdv } from 'app/components/HeroAdv'
import { IconsServices } from 'app/components/IconsServices'
import { Intro } from 'app/components/Intro'
import { News } from 'app/components/News'
import { RoomsPreview } from 'app/components/RoomsPreview'
import { SEO } from 'app/components/SEO'
import { ServicesPreview } from 'app/components/ServicesPreview'
import { Sticker } from 'app/components/Sticker'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'
import { Hotels } from 'app/components/Hotels'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <SEO {...context.seoProps} />
        {context.structuredDataProps ? (
          <StructuredData
            languageCode={pageContext.languageCode}
            {...context.structuredDataProps}
          />
        ) : null}
        {context.headerProps ? (
          <Header
            pageID={context.id}
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            flashNewsProps={
              context.flashNewsProps ? context.flashNewsProps : null
            }
            {...context.headerProps}
          />
        ) : null}
        {context.stickerProps ? <Sticker {...context.stickerProps} /> : null}
        {context.heroProps ? <HeroAdv {...context.heroProps} /> : null}
        {context.benefitsProps ? <Benefits {...context.benefitsProps} /> : null}
        {context.introProps ? <Intro {...context.introProps} /> : null}
        <Media greaterThanOrEqual="ipadHorizontal">
          {context.iconsServicesProps ? (
            <IconsServices {...context.iconsServicesProps} />
          ) : null}
        </Media>
        {context.servicesPreviewProps ? (
          <ServicesPreview {...context.servicesPreviewProps} />
        ) : null}
        {context.newsProps ? <News {...context.newsProps} /> : null}
        <Media lessThan="ipadHorizontal">
          {context.iconsServicesProps ? (
            <IconsServices {...context.iconsServicesProps} />
          ) : null}
          {context.roomsPreviewProps ? (
            <RoomsPreview {...context.roomsPreviewProps} />
          ) : null}
        </Media>
        <Hotels languageCode={pageContext.languageCode} />
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            variant="slider"
            {...context.footerProps}
          />
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.main``
